import React from 'react'
import experience from "../../config/experience"

const Experience = () => {

const jobExp = experience.map((value , index)=>{
  return (
    <div className="card" key={index}>
      <div className="row">
        <div className="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50"
             data-aos-duration="500">
          <div className="card-body cc-experience-header">
            <p>{value.date}</p>
            <div className="h5">{value.company}</div>
          </div>
        </div>
        <div className="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div className="card-body">
            <div className="h5">{value.title}</div>
            <p>{value.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
})

  return (
    <div className="section" id="experience">
  <div className="container cc-experience">
    <div className="h4 text-center mb-4 title">Work Experience</div>
       {jobExp}
  </div>
</div>
  )
}

export default Experience
