import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import Page404 from "./components/NotFound/Page404"
import './App.css';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import MainPage from './components/MainPage';

class App extends Component {
  render() {
    return (
    <div>
      <Router>
                <div>
                    <Switch>
                    <Route exact path="/" component={MainPage}/>
                    <Route exact path="/*" component={Page404}/>
                    </Switch>
                </div>
            </Router>
    </div>
    );
  }
}

export default App;
