import React from 'react'
import {portfolio, portfolio2} from "../../config/portfolio"
const Portfolios = () => {
  const website = portfolio.map((value, index)=>{
    return (
      <div className="col-md-6" key={index}>
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href={value.link}>
                  <figure className="cc-effect"><img src={value.image} alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">{value.name}</div>
                      <p>{value.info}</p>
                    </figcaption>
                  </figure></a></div>
            </div>
    )
  })

  const website2 = portfolio2.map((value, index)=>{
    return (
      <div key={index} className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
        <a href={value.link}>
      <figure className="cc-effect"><img src={value.image} alt="ImagePhoto"/>
        <figcaption>
          <div className="h4">{value.name}</div>
          <p>{value.info}</p>
        </figcaption>
      </figure></a></div>
    )
  })
  return (
    <div className="section" id="portfolio">
  <div className="container">
    <div className="row">
      <div className="col-md-6 ml-auto mr-auto">
        <div className="h4 text-center mb-4 title">Portfolio</div>
        <div className="nav-align-center">
          <ul className="nav nav-pills nav-pills-primary" role="tablist">
            <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#web-development" role="tablist"><i className="fa fa-laptop" aria-hidden="true"></i></a></li>
              <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#graphic-design" role="tablist"><i className="fa fa-picture-o" aria-hidden="true"></i></a></li>
           {/*<li className="nav-item"><a className="nav-link" data-toggle="tab" href="#Photography" role="tablist"><i className="fa fa-camera" aria-hidden="true"></i></a></li> */}
          </ul>
        </div>
      </div>
    </div>
    <div className="tab-content gallery mt-5">
      <div className="tab-pane active" id="web-development">
        <div className="ml-auto mr-auto">
          <div className="row">
            {website}
          </div>
        </div>
      </div>
      <div className="tab-pane" id="graphic-design" role="tabpanel">
        <div className="ml-auto mr-auto">
          <div className="row">
            <div className="col-md-6">
              {website2}
              {/* <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="http://singeldingen.tk/">
                  <figure className="cc-effect"><img src="/design/images/graphic-design-1.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Facebook API</div>
                      <p>React,Redux, Facebook API</p>
                    </figcaption>
                  </figure></a></div>
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#graphic-design">
                  <figure className="cc-effect"><img src="/design/images/graphic-design-2.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Full Stack Django React, GraphQl Project</div>
                      <p>Django, React, Postgress</p>
                    </figcaption>
                  </figure></a></div>
            </div>
            <div className="col-md-6">
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#graphic-design">
                  <figure className="cc-effect"><img src="/design/images/graphic-design-3.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Cube Surface Texture</div>
                      <p>Graphic Design</p>
                    </figcaption>
                  </figure></a></div>
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#graphic-design">
                  <figure className="cc-effect"><img src="/design/images/graphic-design-4.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Abstract Line</div>
                      <p>Graphic Design</p>
                    </figcaption>
                  </figure></a></div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="tab-pane" id="Photography" role="tabpanel">
        <div className="ml-auto mr-auto">
          <div className="row">
            <div className="col-md-6">
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#Photography">
                  <figure className="cc-effect"><img src="/design/images/photography-1.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Photoshoot</div>
                      <p>Photography</p>
                    </figcaption>
                  </figure></a></div>
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#Photography">
                  <figure className="cc-effect"><img src="/design/images/photography-3.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Wedding Photoshoot</div>
                      <p>Photography</p>
                    </figcaption>
                  </figure></a></div>
            </div>
            <div className="col-md-6">
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#Photography">
                  <figure className="cc-effect"><img src="/design/images/photography-2.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Beach Photoshoot</div>
                      <p>Photography</p>
                    </figcaption>
                  </figure></a></div>
              <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom"><a href="#Photography">
                  <figure className="cc-effect"><img src="/design/images/photography-4.jpg" alt="ImagePhoto"/>
                    <figcaption>
                      <div className="h4">Nature Photoshoot</div>
                      <p>Photography</p>
                    </figcaption>
                  </figure></a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Portfolios
