import React from 'react'

const GoogleMap = () => {
  return (
    <div className="mapouter">
    <div className="gmap_canvas">
    <iframe width="100%" height="800px" id="gmap_canvas" title='map'
    src="https://maps.google.com/maps?q=Rotterdam%20The%20netherlands&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
    </iframe>
  </div>
 
  </div>
  )
}

export default GoogleMap

