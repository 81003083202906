const education = [
    { title:"Java /Angular/Scrum- Agile" ,
     description: "2 months Full stack Java course with StormPunt iTrack Coöperatie UA in Createment. The course is coverd java fundamentals part 1 and 2 (OCA), Maven, Spring , Angular as front-end, SQL as Database and Srum as framework.", 
     university:"Createment",
    date:"September 2018 - November 2018",
    degree:"Full Stack Java / Angular Developer"
    },
    { title:"LAMP/MERN Stacks" ,
     description: "One Program is an one year intensive program that divided in 3 section. I have learned the concept of self-sufficiency. First part was coverd LAMP stack (Linux, Apache, MySQL, and PHP) whereas the second was MERN stack (Mongodb, Express, Reactjs, Nodejs).Besides giving workshop and live coding. feel free to ask me more about it.", 
     university:"Restart Network",
    date:"January 2017 - September 2018",
    degree:"Full Stack Developer"
    },
    { title:"Intermediate Python/Django Programming" ,
     description: "Full Study of the Intermediate Python Programming for 10 weeks and learnt topics like Object Oriented Programming, multiprocessingm generators.", 
     university:"ACCENTURE AMSTERDAM",
    date:"Septmbet 2016 - January 2017",
    degree:"Python Developer"
    },
    { title:"Lawyer" ,
    description: "In 5 years in University i have studied the General Law", 
    university:"Law University in Damascus",
   date:"September 2007- September 2013",
   degree:"bachelor degree"
   },
    
]


export default education