import React from 'react'


function Skills(props) {
  return (
    <div className="section" id="skill">
  <div className="container">
    <div className="h4 text-center mb-4 title">Professional Skills</div>
    <div className="card" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">HTML/CSS</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 95+"%"}}></div><span className="progress-value">95%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">Reactjs/Redux</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">Vanilla JavaScript</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">NODEJS/EXPRESS</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">PYTHON/DJANGO</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">SQL/MONGODB/FIREBASE</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">Agile/Scrum</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">php/ codeigniter</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 70+"%"}}></div><span className="progress-value">70%</span>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">java</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 70+"%"}}></div><span className="progress-value">70%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">Wordpress</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">GraphQl/REST API</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">Deployment/Docker/Github</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">Lamp Stack</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 70+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="progress-container progress-primary"><span className="progress-badge">MERN Stack</span>
              <div className="progress">
                <div className="progress-bar progress-bar-primary" data-aos="progress-full" data-aos-offset="10" data-aos-duration="2000" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: 90+"%"}}></div><span className="progress-value">90%</span>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>
  )
}


export default Skills

