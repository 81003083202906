const experience = [
    { title:"Full Stack React/Django Developer" ,
     description: "Working as Full stack Reactjs/Python Django Developer at Rijks ICT Gilde - Ministerie van BinnenlandeZaken.", 
     company:"Rijks ICT Gilde",
    date:"September 2018 - PRESENT"
    },
    { title:"Developer and Prgraming Teacher" ,
    description: "Prviding a programming course in  Matrix Master which has a web development school for refugee and low income people who are willing to learn programming and solve problem though a code. ", 
    company:"MATRIX Master",
    date:"JANUARY 2020 - PRESENT"
    },
    { title:"Full Stack MERN Developer" ,
    description: "Studying and Making websites for foundations in PHP as back-end and Javascript language as front-end", 
    company:"CODE MATRIX ZONE",
    date:"JANUARY 2018 - SEPTEMBER 2018"
    },
    { title:"Full Stack LAMP/MERN Developer" ,
    description: "A combination of learning code and building applications for Restart's partner companies and NGO with LAMP (Linux, Apache, MySQL, PHP) and MERN (MongoDB, Express, React, Nodejs) stacks", 
    company:"RESTART NETWORK",
    date:"JANUARY 2017 - JANUARY 2018"
    },
    
]


export default experience