import React from 'react'
import education from "../../config/education"
const Education = () => {
  const educations = education.map((value, index)=>{
    return (
      <div className="card" key={index}>
      <div className="row">
        <div className="col-md-3 bg-primary" data-aos="fade-right" data-aos-offset="50" data-aos-duration="500">
          <div className="card-body cc-education-header">
            <p>{value.date}</p>
            <div className="h5">{value.degree}</div>
          </div>
        </div>
        <div className="col-md-9" data-aos="fade-left" data-aos-offset="50" data-aos-duration="500">
          <div className="card-body">
            <div className="h5">{value.title}</div>
            <p className="category">{value.university}</p>
            <p>{value.description}</p>
          </div>
        </div>
      </div>
    </div>
    )
  })
  return (
    <div className="section">
  <div className="container cc-education">
    <div className="h4 text-center mb-4 title">Education</div>
    {educations}
  </div>
</div>
  )
}

export default Education
