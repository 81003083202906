import React from 'react'
import details from "../../config/details"
const About = () => {
 const information = details.map((value, index)=>{
   return (
    <div key={index} className={value.row}>
    <div className="col-sm-4"><strong className="text-uppercase">{value.name}</strong></div>
    <div className="col-sm-8">{value.info}</div>
  </div>
   )
 })
 
  return (
    <div className="section" id="about">
  <div className="container">
    <div className="card" data-aos="fade-up" data-aos-offset="10">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="card-body">
            <div className="h4 mt-0 title">About Me</div>
            <p>
            I am a happy person and I spent several years working as an entrepreneur in organizing international events, but a couple of years ago serendipitous events inspired me to get into IT, and I love it!</p>
            <p>
I am self-educated in HTML, CSS, JavaScript, and Python then I started with Restart Network's ONE program for one year. There I got plenty of information about LAMP STACK (Linux, Apache, MySQL, PHP) then I specialized in MERN STACK (MongoDB, Express, ReactJS/Redux, and Nodejs). So far Django the framework of Python has been the most fascinating to me, thus I am building recently my applications with Django as Back-end and React/Redux as Front-end.</p>
<p>
One of the most valuable things that I have learned during my studies is the concept of self-learning. I know how to learn new technologies fast and stay up to date. There is nothing greater than finding opportunities for hard-working people to do awesome things, and it is a fantastic process that I feel lucky to participate in.</p>
<p>
Besides my job, I am volunteering in providing programming courses for refugees or low-income people who are willing to build/change careers.</p>
<p>
I love meeting new people and learning new things, send me an email if you want to know more about me. </p>
                <a href="https://www.linkedin.com/in/abboud-mourad-771b4288/" target="_blank"
                 rel="noopener noreferrer">Learn More</a>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="card-body">
            <div className="h4 mt-0 title">Basic Information</div>
            {information}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default About
