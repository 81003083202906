import React from 'react'
import Header from "./Nav_footer/Header"
import Footer from "./Nav_footer/Footer"
import PageContent from './Page/PageContent';

const MainPage = () => {
  return (
    <div>
      <Header/>
      <PageContent class="page-content"/>
      <Footer />
    </div>
  )
}

export default MainPage
