import React from 'react'
import Profile from './Contain/Profile';
import About from './Contain/About';
import Skills from './Contain/Skills';
import Portfolios from './Contain/Portfolios';
import Experience from './Contain/Experience';
import Education from './Contain/Education';
// import Reference from './Contain/Reference';
import Contact from './Contain/Contact';

const PageContent = () => {
  return (
    <div>
      <Profile/>
      <About/>
      <Skills/>
      <Portfolios/>
      <Experience/>
      <Education/>
      {/* <Reference/> */}
      <Contact/>
    </div>
  )
}

export default PageContent
