const portfolio = [
    {name:"Matrix Master Project" , info: "Education Platform for sharing courses, build with Django, React, Postgress, GraphQL", link:"https://matrixmaster.info", image:"/design/images/project-1.jpg"},
    {name:"Code Matrix Zone Project" , info:'Building web and mobile application. build with wordpress and Reactjs',link:"http://codematrix.nl/", image:"/design/images/project-2.jpg"},
    {name:"Royal Rose" , info:'Perfum online shop, Front-end wordpress and back-end with Django, React, Postgress', link:"https://royal-rose.nl/", image:"/design/images/project-3.jpg"},
    {name:"Sham barbershop Website" , info: "Vanilla JavaScript, Bootstrap", link:"http://www.shambarbershop.nl/", image:"/design/images/project-4.jpg"},

]
// const portfolio = [
//     {name:"Recent Design Website" , info: "JavaScript, Bootstrap", link:"http://www.shambarbershop.nl/", image:"/design/images/project-1.jpg"},
//     {name:"Full Stack MERN Project" , info:'MongoDb , Express, Reactjs/Redux, Nodejs',link:"http://codematrix.nl/", image:"/design/images/project-2.jpg"},
//     {name:"Full Stack Django React Project" , info:'Django, React, Postgress', link:"https://v-lab.ubrijk.nl/trend-dossiers/", image:"/design/images/project-3.jpg"},
//     {name:"Reactjs,Redux,Firebase" , info:'Implementing Firebase as back with Reactjs and Redux',link:"http://www.layalialsham.tk/", image:"/design/images/project-4.jpg"},

// ]

const portfolio2 = [
    {name:"Full Stack Django React Project" , info:'Django, React, Postgress', link:"https://v-lab.ubrijk.nl/trend-dossiers/", image:"/design/images/graphic-design-3.jpg"},

    // {name:"Full Stack Django React Project" , info:'Django, React, Postgress', link:"https://v-lab.ubrijk.nl/trend-dossiers/", image:"/design/images/graphic-design-3.jpg"},
    // {name:"Facebook API" , info:'React,Redux, Facebook API',link:"http://singeldingen.tk/", image:"/design/images/graphic-design-4.jpg"},

]

export { 
    portfolio,
    portfolio2}