import React from 'react'
import { socialMedia } from "../../config/socialMedia"
import cv from "../../../cvFile/CV Abdullah Mourad 2019.pdf"





const Profile = () => {
  const social = socialMedia.map((value, index)=>{
    return (
      <a key ={index} 
        className="btn btn-default btn-round btn-lg btn-icon" 
        href={value.link}  title={`Follow me on ${value.icon}`} target="_blank" rel="noopener noreferrer">
          <i className={`fa fa-${value.icon}`}>
          </i>
      </a>
    )
  })
  return (
    <div className="profile-page">
  <div className="wrapper">
    <div className="page-header page-header-small" filter-color="green">
      <div className="page-header-image" data-parallax="true" style={{backgroundImage: "url('/design/images/cc-bg-1.jpg')"}}></div>
      <div className="container">
        <div className="content-center">
          <div className="cc-profile-image"><a href="#about"><img src="/design/images/pic.jpg" alt="pic"/></a></div>
          <div className="h2 title">Abboud Mourad</div>
          <p className="category text-white">Full Stack Developer</p>
            <a className="btn btn-primary smooth-scroll mr-2" href="#contact" 
               data-aos="zoom-in" data-aos-anchor="data-aos-anchor">Contact Me</a>

            <a className="btn btn-primary" href={cv} 
               data-aos="zoom-in" data-aos-anchor="data-aos-anchor"  download>Download CV</a>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="button-container">
            {social}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  )
}

export default Profile


